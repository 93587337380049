<template>
<div class="site-main">
  <div class="site-content"><router-view></router-view></div>
  <AlarmNotify @shown="showAlarmList" />
  <ModalList v-model="showAlarmModal" />
</div>
</template>
<script>
import {mapState} from 'vuex'
import AlarmNotify from '@/views/alarm/Notify'
import ModalList from '@/views/alarm/ModalList'
export default {
  name: 'Home',
  components: {
    AlarmNotify,
    ModalList,
  },
  data(){
    return {
      timeout: null,
      showAlarmModal: false,
    }
  },
  created: function () {
    this.$store.commit('auth/init');
    // console.log('first page created');
    if(!this.user || !this.token){
      this.$Message.info('登录已过期');
      let url = '/login';
      // console.log('log out', this.domains)
      // if(this.domains.version == 'new'){
      //   url = '/loginNew';
      // }
      this.$router.replace(url);
      // this.$router.replace('/login');
      return;
    }
    this.initMenus();
  },
  computed: {
    ...mapState('auth', ['token', 'user', 'appType', 'customerId']),
  },
  watch: {
    appType(){
      this.debounceRefreshAgent();
    },
  },
  mounted: function(){
    this.debounceRefreshAgent();
  },
  methods: {
    initMenus: function () {
      this.$axios.post(`common/auth/QueryMyOperations`, {}).then(res => {
        if (res.code !== 0) return;
        // res.data.menus.splice(0, 0, {
        //   code: "Desktop",
        //   name: "工作台",
        //   isLeaf: true,
        //   sort: 1
        // });
        // this.$set(this, 'modules', res.data.menus);
        // this.$set(this, 'config', res.data.config);
        // this.active = this.modules[0];
        // this.$emit('menuClick', this.active);
        res.data.config.logo = res.data.config.appLogo;
        for(let key in res.data.other){
          res.data.config[key] = res.data.other[key];
        }
        this.$store.commit('auth/setCustConfig', {config: res.data.config, operations: res.data.operations});
        // console.log('click mounted', this.modules, this.active, this.config)
      });
    },
    showAlarmList: function(){
      this.showAlarmModal = true;
    },
    debounceRefreshAgent: function(){
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.refreshAgent, 500);
      this.$store.commit('cmd/initOldLogStatus');
    },
    refreshAgent: function(){
      if(!this.token)return;
      if(this.appType == '')return;
      this.$store.dispatch('common/getDeviceTypes');
      this.$store.dispatch('common/getBigSmallTypes');
    }
  },
}
</script>
<style>
.site-main{
  width: 100%;
  height: 100%;
  /* border: solid 1px red; */
  position: relative;
  overflow: hidden;
}
.site-content{
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* border: solid 1px blue; */
  display: flex;
  flex-direction: column;
}
</style>